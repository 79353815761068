import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Page = () => (
  <Layout>
    <Seo title="Brukervilkår" />
    <div className="longtext">
      <h1>Brukervilkår</h1>
      <p className="secondary">Sist endret 28. april 2021</p>

      <p>
        Takk for at du bruker Mente! Mente er en tjeneste som hjelper deg med
        kontinuerlig forbedring gjennom smarte lister, oppgaver og refleksjon.
      </p>

      <h2>Oppsummert</h2>
      <ul>
        <li>
          Du eier dine egne data, de er trygt lagret hos vår skyleverandør og
          deles ikke med andre uten at du selv velger det
        </li>
        <li>
          Appen, funksjonalitet, vilkår, m.m. blir fortløpende endret og
          oppdatert for å lage den best mulige tjenesten
        </li>
        <li>
          Tjenesten kan brukes gratis på ubestemt tid. Dersom du velger å
          oppgradere til et abonnement kan du håndtere/avslutte dette på vanlig
          måte i App Store
        </li>
        <li>
          Appen er som den er. Vi gir ingen garantier og tar heller ikke noe
          ansvar for bruk av tjenesten, utover det som er lovpålagt
        </li>
      </ul>

      <h2>For deg som vil ha detaljene</h2>

      <h2>Parter</h2>
      <p>
        Mente eies av Mente AS (“vi”, “oss”) i Norge. Når du bruker Mente,
        samtykker du også til disse vilkårene. Dette er en avtale mellom deg og
        Mente AS, ikke Apple. Mente AS er alene ansvarlig for innholdet i appen.
      </p>
      <p>
        Du er selv ansvarlig for å overholde relevante avtalevilkår fra
        tredjeparter når du bruker Mente, f.eks. ovenfor din mobiloperatør.
      </p>

      <h2>Du eier dine egne data</h2>
      <p>
        Din personlige informasjon og egne data som du legger inn i appen
        tilhører selvsagt deg.
        <br />
        Personvern og sikkerhet er viktig for oss, og du kan{" "}
        <Link to="/privacy/">lese personvernerklæringen vår her</Link>.
      </p>

      <h2>Du leier tilgang til tjenesten</h2>
      <p>
        Så lenge du har en aktiv brukerkonto, får du en begrenset,
        ikke-eksklusiv, ikke-overførbar og midlertidig lisens til å bruke Mente.
        Hva du får tilgang til vil variere etter om du har gratisversjon eller
        en betalt versjon. Tilgangen gir deg ikke noen form for eierskap til
        tjenesten eller innholdet du får tilgang til. Du kan f.eks. ikke låne
        ut, videreselge eller kopiere noen del av tjenesten eller innholdet. Du
        kan ikke bruke Mentes iOS-apper på noen annen måte enn det som er
        tillatt i Apple sine vilkår for App Store.
      </p>

      <p>
        Tjenesten vil oppdateres jevnlig, f.eks. med feilrettinger, nytt innhold
        og nye funksjoner. Det kan også være vi endrer eller fjerner innhold og
        funksjonalitet. I sjeldnere tilfeller kan det også bety at tjenesten er
        utilgjengelig og i ytterste konsekvens at den legges ned.
      </p>

      <p>
        Du kan når som helst stoppe å bruke tjenesten og avslutte abonnementet.
        Ta gjerne kontakt om du vurderer dette, i tilfelle det skyldes en
        utfordring vi kan hjelpe deg med. Skulle du likevel ønske å avslutte
        abonnementet gjøres dette via App Store. Det er ikke nok å bare slette
        appen.
      </p>

      <h2>Misbruk og lovbrudd</h2>
      <p>
        Vi ønsker at Mente skal oppleves nyttig og positivt for alle parter.{" "}
      </p>

      <p>
        Enhver form for ulovlig, etisk uakseptabelt, kopibeskyttet eller
        skadelig innhold og aktivitet er ikke tillatt. Misbruk av tjenesten er
        heller ikke tillatt. Eksempeler på dette (ikke uttømmende) er å forsøke
        å omgå sikkerhetsmekanismene i appen, forsøke å angripe appen eller
        brukerne, innbrudd, ødeleggelser eller tyveri av data, eller forsøk på å
        bruke tjenesten på andre måter enn det brukergrensesnittet legger opp
        til.
      </p>

      <p>
        Vi forbeholder oss retten til umiddelbart å fjerne innhold, stenge ned
        og avslutte tilganger etter eget skjønn.
      </p>

      <h2>Ansvarsbegrensning</h2>
      <p>
        Du forstår at tjenesten tilbys som den er og etter beste evne. Vi vil
        selvsagt gjøre det vi kan for at du skal få en god opplevelse når du
        bruker Mente, men vi gir ingen garantier og tar heller ikke noe ansvar
        for bruk av tjenesten utover det som er lovpålagt. For eksempel gir vi
        ingen garantier om tilgjengelighet, funksjonalitet eller at tjenesten er
        fri for feil. Vi er heller ikke ansvarlig for skader eller tap som
        eventuelt måtte oppstå. Du forstår og aksepterer at bruk av tjenesten
        skjer på egen risiko. I den utstrekning det foreligger noen garantier
        eller krav som ikke kan fraskrives i henhold til lov, skal Mente AS
        alene, ikke Apple, være ansvarlig.
      </p>

      <h3>Intellektuelle rettigheter</h3>
      <p>
        I den grad Mente er pålagt å sørge for erstatning etter gjeldende lov,
        skal Mente AS, ikke Apple, utelukkende være ansvarlig for
        etterforskning, forsvar, oppgjør og utskrivning av ethvert krav, dersom
        Mente, eller din bruk av Mente, krenker en tredjeparts intellektuelle
        rettigheter.
      </p>

      <h2>Tredjepartsmottakere</h2>
      <p>
        Ved aksept av denne avtalen samtykker du til at Apple, eller Apples
        datterselskaper, kan håndheve denne avtalen for apper som kjører på
        Apple sine plattformer.
      </p>

      <h2>U.S. Legal Compliance</h2>
      <p>Du garanterer at du:</p>
      <ol>
        <li>
          ikke er lokalisert i et land som er underlagt en amerikansk
          regjeringsembargo, eller som av den amerikanske regjeringen har blitt
          utpekt som et "terrorstøttende" land; og
        </li>
        <li>
          ikke er oppført på noen liste over forbudte eller begrensede parter av
          den amerikanske regjeringen.
        </li>
      </ol>

      <h2>Support</h2>
      <p>
        Betalende kunder med et aktivt abonnement kan kontakte oss på{" "}
        <a href="mailto:hello@mente.app">hello@mente.app</a> for å få support.
        Apple er ikke ansvarlig for noen form for support eller vedlikehold.
      </p>

      <h2>Aldersgrense</h2>
      <p>
        Du må være 16 år eller eldre for å bruke tjenesten. Dersom du er yngre,
        må du gjennomgå denne avtalen med foreldrene dine og få de til å
        kontakte oss for samtykke på{" "}
        <a href="mailto:hello@mente.app">hello@mente.app</a>.
      </p>

      <h2>Vilkår, tjenester og priser kan endres</h2>
      <p>
        Vi jobber hele tiden med å videreutvikle Mente og som følge av det kan
        både vilkår, tjenesten i seg selv og priser endres. Hva som er betalt
        funksjonalitet og hva som er gratis funksjonalitet kan også bli endret.
        Vi vil forsøke å informere i god tid der dette er naturlig og mulig.
        Større endringer blir kommunisert via epost.
      </p>

      <p>**</p>

      <p>
        Det var alt. Om du har spørsmål, send oss gjerne en mail på{" "}
        <a href="mailto:hello@mente.app">hello@mente.app</a>. Dersom du vil nå
        oss via post, er adressen:
        <br />
        Mente AS
        <br />
        Jakobsbråtan 12, 2008 Fjerdingby
        <br />
        Norway
      </p>
    </div>
  </Layout>
)

export default Page
